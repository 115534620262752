<template>
  <div class="d-flex flex-column align-items-center text-center col-12 vue-container">
    <div class="mt-4 penrose-dashboard-text col-10">CHANGE YOUR PASSWORD!</div>
    <div class="mt-4 password-info-text col-11 col-xl-3">
      Your password must be at least six characters and should include a combination of numbers, letters (uppercase & lowercase) and special characters
    </div>
    <input v-model="email" id="emailAddress" type="email" class="mt-4 col-11" required placeholder="Email">
    <input v-model="registrationKey" type="text" class="mt-3 col-11" placeholder="Unique Key" required>
    <input v-on:change="validatePasswordStrength" v-model="password" type="password" class="mt-3 col-11" placeholder="New Password" required>
    <div class="m-2" v-if="passwordWeak">Password is too weak</div>
    <button @click="updateClicked()" class="mt-4 col-xl-2 col-7 update-button">Update</button>
    <div class="mt-4" v-if="successfulPasswordChange === true">Successfully updated password</div>
    <div class="mt-4" v-if="successfulPasswordChange === false">Error: Please try again</div>
  </div>
</template>

<script>
import passwordValidator from 'password-validator';
import DashboardService from "@/services/dashboardService";
import bcrypt from 'bcryptjs';
import {mapMutations} from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: undefined,
      password: undefined,
      registrationKey: undefined,
      passwordWeak: undefined,
      successfulPasswordChange: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    validatePasswordStrength() {
      const schema = new passwordValidator();
      schema.is().min(8);
      schema.has().uppercase();
      schema.has().lowercase();
      schema.has().digits(1);
      schema.has().not().spaces();
      schema.has().symbols();
      const passwordCheck = schema.validate(this.password);
      this.passwordWeak = passwordCheck === false;
    },
    async updateClicked() {
      await this.updateNewPassword();
      this.handleUpdatedPasswordResponse()
    },
    async updateNewPassword() {
      const response = await DashboardService.updatePassword({
        email: this.email,
        hashedPassword: bcrypt.hashSync(this.password, 10),
        registrationKey: this.registrationKey,
      });
      this.registrationKeyValid = response.data.Key;
    },
    handleUpdatedPasswordResponse() {
      this.successfulPasswordChange = !!this.registrationKeyValid;
    }
  },
  beforeMount() {
    this.setIsLoading(true);
    this.setUserData(undefined);
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.penrose-dashboard-text {
  font-size: 45px;
  color: #EE5C36;
  line-height: 1.1;
}

.password-info-text {
  font-size: 14px;
  color: #949494;
}

input {
  border: 0;
  height: 50px;
  border-radius: 12px;
  width: 270px;
  font-size: 14px;
  padding-left: 10px;
}

::placeholder {
  padding-left: 10px;
  font-size: 14px;
}

.update-button {
  background-color: #EE5C36;
  color: #FFFFFF;
  border: 0;
  border-radius: 12px;
  height: 40px;
}

@media screen and (max-width: 500px ) {
  .penrose-dashboard-text {
    font-size: 35px;
  }
}
</style>
